
import axios from "axios";
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import {
  processMath,
  backendDelete,
  backendPost,
  ExerciseFilters,
  defaultExerciseFiltersValue,
} from "@/utils";
import ExportPdfModal from "../components/ExportPdfModal.vue";
import DeleteExercisesModal from "../components/DeleteExercisesModal.vue";
import ExerciseFiltersModal from "@/components/ExerciseFiltersModal.vue";

export default defineComponent({
  name: "ExercisesView",
  components: { ExportPdfModal, DeleteExercisesModal, ExerciseFiltersModal },
  data() {
    return {
      exercises: [],
      bulkOpsButtonEnabled: false,
      whichExercises: "",
      selectedPage: 0,
      numberOfPages: 0,
      otherUsers: [],
      selectedExercises: [] as { id: string; statement: string }[],
      exerciseFilters: defaultExerciseFiltersValue(),
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      authenticated: "auth/isAuthenticated",
      admin: "auth/isAdmin",
      adminOrEmp: "auth/isAdminOrEmp",
      getWhichExercises: "nav/getWhichExercises",
      getExercisesPage: "nav/getExercisesPage",
    }),
  },
  methods: {
    ...mapMutations({
      setWhichExercises: "nav/setWhichExercises",
      setExercisesPage: "nav/setExercisesPage",
    }),
    checkboxChanged(): void {
      if (this.selectedExercises.length === 0) {
        this.bulkOpsButtonEnabled = false;
      } else {
        this.bulkOpsButtonEnabled = true;
      }
    },
    processMath(text: string): string {
      return processMath(text.replaceAll("$", "#"));
    },
    editExercise(id: bigint): void {
      this.$router.push("edit-exercise/" + id.toString());
    },
    async duplicateExercises(exercises: { id: string }[]): Promise<void> {
      const ids: string[] = exercises.map((ex) => ex.id);
      for (let i = 0; i < ids.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const id: string = ids[i] ? ids[i]!.toString() : "";
        const params = new FormData();
        params.append("id", id);
        await backendPost("/api/exercises/duplicate", params, 201);
      }
      this.clearAllCheckboxes();
      this.refreshList(
        this.whichExercises,
        this.selectedPage,
        this.exerciseFilters
      );
    },
    exportPdf(): void {
      this.clearAllCheckboxes();
      this.refreshList(
        this.whichExercises,
        this.selectedPage,
        this.exerciseFilters
      );
    },
    async deleteExercises(exercises: { id: string }[]): Promise<void> {
      const ids: string[] = exercises.map((ex) => ex.id);
      for (let i = 0; i < ids.length; i++) {
        await backendDelete("/api/exercises/remove?id=" + ids[i], 200);
      }
      this.clearAllCheckboxes();
      this.refreshList(
        this.whichExercises,
        this.selectedPage,
        this.exerciseFilters
      );
    },
    clearAllCheckboxes(): void {
      this.selectedExercises = [];
      this.bulkOpsButtonEnabled = false;
    },
    updateFilters(exerciseFilters: ExerciseFilters): void {
      this.exerciseFilters = exerciseFilters;
      this.refreshList(
        this.whichExercises,
        this.selectedPage,
        this.exerciseFilters
      );
    },
    refreshList(
      whichExercises: string,
      page: number,
      filters: ExerciseFilters
    ): void {
      this.setWhichExercises(whichExercises);
      this.setExercisesPage(page);
      let filter = encodeURIComponent(JSON.stringify(filters));

      switch (whichExercises) {
        case "all":
          axios
            .get(
              "api/exercises/listAllWithPagination?page=" +
                page +
                "&exerciseFilterDataJson=" +
                filter
            )
            .then((response) => {
              this.exercises = response.data["exercises"];
              this.numberOfPages = response.data["numberOfPages"];
              this.selectedPage =
                page > this.numberOfPages - 1 ? this.numberOfPages - 1 : page;
            });
          break;
        case "my":
          axios
            .get(
              "api/exercises/listMineWithPagination?page=" +
                page +
                "&exerciseFilterDataJson=" +
                filter
            )
            .then((response) => {
              this.exercises = response.data["exercises"];
              this.numberOfPages = response.data["numberOfPages"];
              this.selectedPage =
                page > this.numberOfPages - 1 ? this.numberOfPages - 1 : page;
            });
          break;
        default:
          if (this.admin) {
            axios
              .get(
                "api/exercises/listCreatedByUserWithPagination?page=" +
                  page +
                  "&userId=" +
                  whichExercises +
                  "&exerciseFilterDataJson=" +
                  filter
              )
              .then((response) => {
                this.exercises = response.data["exercises"];
                this.numberOfPages = response.data["numberOfPages"];
                this.selectedPage =
                  page > this.numberOfPages - 1 ? this.numberOfPages - 1 : page;
              });
          }
          break;
      }
    },
  },
  mounted() {
    this.whichExercises = this.getWhichExercises || "all";
    this.selectedPage = this.getExercisesPage || 0;
    this.refreshList(
      this.whichExercises,
      this.selectedPage,
      this.exerciseFilters
    );
    if (this.admin) {
      axios.get("api/users/indexOtherUsers").then((response) => {
        if (response.status == 200) {
          this.otherUsers = response.data;
        }
      });
    }
  },
});

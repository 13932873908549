
import { backendGet, defaultExerciseFiltersValue } from "@/utils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExerciseFiltersModal",
  props: ["exerciseFilters"],
  data() {
    return {
      exerciseFiltersData: defaultExerciseFiltersValue(),
      difficultyLevels: ["Usor", "Mediu", "Avansat"],
      academicPrograms: [] as { id: string; name: string }[],
      grades: [] as { id: string; name: string }[],
      chapters: [] as { id: string; name: string }[],
      subchapters: [] as { id: string; name: string }[],
    };
  },
  methods: {
    clearFilters() {
      this.exerciseFiltersData = defaultExerciseFiltersValue();
      this.chapters = [] as { id: string; name: string }[];
      this.subchapters = [] as { id: string; name: string }[];
      this.$emit("update-filters", this.exerciseFiltersData);
    },
    chaptersRefresh() {
      if (this.exerciseFiltersData.gradeId != "") {
        backendGet(
          "/api/chapters/indexById?gradeId=" + this.exerciseFiltersData.gradeId,
          (response) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.chapters = response.data.map((elem: { [x: string]: any }) => {
              return { id: elem["id"], name: elem["name"] };
            });
          }
        );
      }
      this.subchaptersRefresh();
      this.exerciseFiltersData.chapterId = "";
      this.exerciseFiltersData.subchapterId = "";
    },
    subchaptersRefresh() {
      if (
        this.exerciseFiltersData.gradeId != "" &&
        this.exerciseFiltersData.chapterId != ""
      ) {
        backendGet(
          "/api/subchapters/indexByIds?gradeId=" +
            this.exerciseFiltersData.gradeId +
            "&chapterId=" +
            this.exerciseFiltersData.chapterId,
          (response) => {
            this.subchapters = response.data.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (elem: { [x: string]: any }) => {
                return { id: elem["id"], name: elem["name"] };
              }
            );
          }
        );
      }
      this.exerciseFiltersData.subchapterId = "";
    },
  },
  mounted() {
    this.exerciseFiltersData = this.exerciseFilters;
    backendGet("/api/academic-programs/index", (response) => {
      this.academicPrograms = response.data.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (elem: { [x: string]: any }) => {
          return { id: elem["id"], name: elem["name"] };
        }
      );
    });
    backendGet("/api/grades/index", (response) => {
      this.grades = response.data.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (elem: { [x: string]: any }) => {
          return { id: elem["id"], name: elem["name"] };
        }
      );
    });
  },
});
